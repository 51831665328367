//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.header
{
    $headerHeight: 60px;

    width:         100%;
    height:        $headerHeight;
    background:    $white;
    position:      fixed;
    top:           0;
    z-index:       10;
    display:       flex;
    align-items:   center;

    .headerContent
    {
        display:               grid;
        grid-template-columns: repeat(3, 1fr);
        align-items:           center;

        > div
        {
            display:     flex;
            align-items: center;

            &:nth-child(2)
            {
                justify-content: center;
            }

            &:last-child
            {
                justify-content: end;
            }
        }

        .menuButton
        {
            height:          $headerHeight;
            width:           $headerHeight;
            display:         flex;
            justify-content: center;
            align-items:     center;
            cursor:          pointer;

            $iconSize:       calc($headerHeight / 2);

            svg
            {
                height: $iconSize;
                width:  $iconSize;
            }
        }

        .menuLabelWrapper
        {
            display:     flex;
            gap:         20px;
            align-items: center;
            height:      fit-content;

            .menuLabelItem
            {
                display: flex;
                gap:     8px;

                svg
                {
                    width: 20px;
                }

                span
                {
                    text-align:  center;
                    line-height: 100%;
                    display:     inline-flex;
                    align-items: center;
                }

                .enabled
                {
                    color: $mintGreen;
                }

                .disabled
                {
                    color: $brightRed;
                }
            }

            a
            {
                text-decoration: none;
            }
        }
    }
}
