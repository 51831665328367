//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

$headerHeight: 60px;

.homeContent
{
    $homePadding:   20px;

    margin:         calc($headerHeight + $homePadding) 0 $homePadding 0;
    display:        flex;
    flex-direction: column;
    gap:            20px;
}
