//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@mixin scaleOnHover($scale: 1.1)
{
    transition:             scale 0.1s linear;
    -webkit-font-smoothing: subpixel-antialiased;

    &:hover
    {
        scale: $scale;
    }
}

@mixin colorOnHover($color)
{
    transition: color 0.1s linear;

    &:hover
    {
        color: $color;
    }
}

@keyframes slideInFromRight
{
    0%
    {
        transform: translateX(100px);
        opacity:   0;
    }

    100%
    {
        transform: translateX(0);
        opacity:   1;
    }
}

@keyframes slideInFromLeft
{
    0%
    {
        transform: translateX(-100px);
        opacity:   0;
    }

    100%
    {
        transform: translateX(0);
        opacity:   1;
    }
}

@keyframes slideInFromBottom
{
    0%
    {
        transform: translateY(50px);
        opacity:   0;
    }

    100%
    {
        transform: translateY(0);
        opacity:   1;
    }
}

@keyframes fadeIn
{
    0%
    {
        opacity: 0;
    }

    100%
    {
        opacity: 1;
    }
}