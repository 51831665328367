//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.notFoundScreen
{
    width:           100%;
    height:          calc(100vh - 120px);
    display:         flex;
    flex-flow:       column;
    align-items:     center;
    justify-content: center;

    h1
    {
        font-size:   120px;
        font-weight: $fontWeightBold;
    }

    p
    {
        font-size: 30px;
    }
}