//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.orderProductListItem
{
    display:               grid;
    grid-template-columns: repeat(5, 1fr);

    > div
    {
        display:     flex;
        align-items: center;
        grid-column: span 2;

        &:nth-child(2)
        {
            grid-column:     span 1;
            justify-content: center;
        }

        &.positionDetails
        {
            display: flex;
            gap:     10px;

            > div
            {
                min-width:   40px;
                font-weight: $fontWeightSemiBold;

                &:first-child
                {
                    min-width: 180px;
                }

                &:nth-child(3)
                {
                    font-weight: $fontWeightRegular;
                }
            }
        }
    }

    .orderProductStatusBar
    {
        display:     flex;
        align-items: center;
        gap:         10px;
        grid-column: span 2;

        &.right
        {
            justify-content: flex-end;
        }
    }
}
