//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$headerHeight: 80px;

.overlayWrapper
{
    position:        fixed;
    top:             0;
    left:            0;
    width:           100vw;
    height:          100vh;
    background:      $black50;
    display:         flex;
    justify-content: center;
    align-items:     center;
    transition:      opacity .1s linear;
    z-index:         100;

    .overlayContainer
    {
        position:      relative;
        display:       flex;
        flex-flow:     column;
        width:         800px;
        max-width:     90%;
        min-height:    0;
        max-height:    90%;
        background:    $white;
        border-radius: 10px;
        transition:    scale .2s cubic-bezier(0.01, 0.01, 0.13, 1.5);

        &::after
        {
            content:       '';
            display:       block;
            width:         100%;
            height:        20px;
            background:    linear-gradient(to top, $white 25%, $transparent);
            position:      absolute;
            bottom:        0;
            left:          0;
            border-radius: 0 0 15px 15px;
            z-index:       2;
        }

        .overlayContent
        {
            flex:       1;
            padding:    20px 40px 40px 40px;
            overflow-y: auto;
            height:     calc(100% - 40px);

            @include hideScrollbar;
        }

        .overlayHeader
        {
            position:        relative;
            height:          $headerHeight;
            display:         flex;
            justify-content: flex-end;
            flex-shrink:     0;
            align-items:     center;

            .overlayCloseButton
            {
                cursor:       pointer;
                margin-right: 20px;

                @include scaleOnHover(1.2)
            }

            &::after
            {
                content:    '';
                display:    block;
                width:      100%;
                height:     15px;
                background: linear-gradient(to bottom, $white, $transparent);
                position:   absolute;
                top:        100%;
                left:       0;
                z-index:    2;
            }
        }
    }

    &.overlayHidden
    {
        opacity:        0;
        pointer-events: none;

        .overlayContainer
        {
            scale: 0.8;
        }
    }

    &.wide
    {
        .overlayContainer
        {
            width: 1200px;

            .overlayContent
            {
                padding: 20px 60px 40px 60px;
            }
        }
    }

    &.small
    {
        .overlayContainer
        {
            width: 600px;

            .overlayContent
            {
                padding-top: 0;
            }
        }
    }

    &.fullScreen
    {
        background: $transparent;

        .overlayContainer
        {
            width:         100vw;
            max-width:     100vw;
            height:        100vh;
            max-height:    100vh;
            border-radius: 0;
            scale:         1;
        }

        .overlayHeader
        {
            justify-content: flex-start;
            margin-left:     20px;

            .overlayCloseButton
            {
                cursor: pointer;
            }
        }
    }
}
