//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.notificationHandler
{
    position: absolute;
    width:    100%;

    > div
    {
        z-index: $zIndexNotification !important;
    }
}

.notificationHandlerCenter
{
    // Disabled because of notification library classnames
    /* stylelint-disable */
    :global(.notification-container)
    {
        left:            0;
        right:           0;
        overflow:        hidden;
        margin:          0 auto;
        display:         flex;
        justify-content: center;
    }

    :global(.notification-enter)
    {
        transform: scale(0.8);
        opacity:   0;
    }

    :global(.notification-enter.notification-enter-active)
    {
        transform: scale(1);
        opacity:   1;
    }

    :global(.notification-exit)
    {
        transform: scale(1);
        opacity:   1;
    }

    :global(.notification-exit.notification-exit-active)
    {
        transform: scale(0.8);
        opacity:   0;
    }

    /* stylelint-enable */
}

.notificationHandlerLeft
{
    // Disabled because of notification library classnames
    /* stylelint-disable */
    :global(.notification-container)
    {
        left:  0;
        right: auto;
    }

    :global(.notification-enter)
    {
        transform: translate3d(-100%, 0, 0);
    }

    :global(.notification-enter.notification-enter-active)
    {
        transform: translate3d(0, 0, 0);
    }

    :global(.notification-exit)
    {
        transform: translate3d(0, 0, 0);
    }

    :global(.notification-exit.notification-exit-active)
    {
        transform: translate3d(-100%, 0, 0);
    }

    /* stylelint-enable */
}

// Disabled because of notification library classnames
/* stylelint-disable */
:global(.notification-container)
{
    width:          100%;
    pointer-events: none;
    padding:        60px 0 20px 0;

    div
    {
        width:          100%;
        display:        flex;
        flex-direction: column;
        align-items:    center;

        :global(.notification)
        {
            padding:             36px 36px;
            width:               700px;
            height:              fit-content;
            max-width:           90%;
            text-align:          center;
            border-radius:       12px;
            font-size:           15px;
            font-weight:         $fontWeightMedium;
            position:            relative;
            opacity:             1;
            pointer-events:      all;
            box-shadow:          0 4px 8px 0 $black20,
                                 0 6px 20px 0 $black20;
            transition-duration: 0.3s;

            &:before
            {
                display: none
            }

            &:global(.notification-info)
            {
                background: $orange;
                color:      $white
            }

            &:global(.notification-success)
            {
                background: $mintGreen;
                color:      $white;
            }

            &:global(.notification-error)
            {
                background: $brightRed;
                color:      $white;
            }
        }
    }
}

/* stylelint-enable */
