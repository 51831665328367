//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.statusButton
{
    padding:       14px 10px;
    height:        fit-content;
    font-size:     16px;
    background:    $lightGray;
    border-radius: 10px;
    border:        none;
    cursor:        pointer;
    transition:    scale 0.2s linear,
                   background 0.2s linear,
                   color 0.2s linear;

    &.active
    {
        background:  $black;
        color:       $white;
        scale:       1.05;
        font-weight: $fontWeightSemiBold;

        &.orange
        {
            background:  $orange;
            font-weight: $fontWeightSemiBold;
            color:       $white;
        }

        &.gold
        {
            background:  linear-gradient(to right, $gold, $lightGold 57%, $darkGold);;
            font-weight: $fontWeightSemiBold;
            color:       $black;
        }

        &.green
        {
            background:  $mintGreen;
            font-weight: $fontWeightSemiBold;
            color:       $white;
        }
    }

    &.pressed
    {
        scale: 0.95;
    }
}