//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$inputHeight:          50px;
$multilineInputHeight: 80px;

.textInput
{
    width:         100%;
    background:    $white;
    border:        1px solid $gray;
    height:        $inputHeight;
    min-height:    $inputHeight;
    outline:       none;
    font-family:   $fontSourceGeneralBody;
    font-size:     14px;
    color:         $fontSourceGeneralBody;
    padding:       0 0 0 10px;
    box-sizing:    border-box;
    resize:        none;
    border-radius: 8px;

    &.textInputResizable
    {
        resize: both;
    }

    &.textInputDisabled
    {
        color:  $gray;
        cursor: not-allowed;
    }

    &.textInputMultiLine
    {
        padding-top: 9px;
        height:      $multilineInputHeight;
        min-width:   150px;
        max-height:  500px;
        max-width:   500px;
    }

    &.textInputSingleLine
    {

    }

    &.textInputWithPostIcon
    {
        padding-right: $inputHeight;
    }

    &::placeholder
    {
        color:      $gray;
        font-style: italic;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
    {
        appearance: none;
        margin:     0;
    }

    &[type="number"]
    {
        appearance: textfield;
    }

    &:focus
    {
        border-color: $gray;
    }
}
