//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.spacerWrapper
{
    height:    fit-content;
    display:   flex;
    flex-flow: column;
}

.line
{
    width:      100%;
    height:     0;
    background: $doveGray;

    &.dashed
    {
        background: none;
        border-top: .5px dashed $doveGray;
    }

    &.visible
    {
        height: 1px;
    }
}

.spacer
{
    display:    inline-block;
    width:      100%;
    visibility: hidden;
}
