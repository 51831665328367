//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

/* stylelint-disable no-descending-specificity */

$primaryColor:  $orange;
$disabledColor: $lightGray;

.buttonWrapper
{
    position: relative;
    height:   100%;

    .button
    {
        display:          flex;
        flex-flow:        column nowrap;
        justify-content:  center;
        align-items:      center;
        color:            $white;
        border:           1px solid $primaryColor;
        padding:          11px 16px;
        min-width:        300px;
        border-radius:    6px;
        background-color: $primaryColor;
        cursor:           pointer;
        font-family:      $fontSourceGeneralBody;

        @include scaleOnHover();

        &.iconOnly
        {
            .iconContainer
            {
                padding: 0 !important;
            }
        }

        &[disabled]
        {
            cursor:           auto;
            color:            $black;
            opacity:          0.4;
            border-color:     $disabledColor;
            background-color: $disabledColor;
            pointer-events:   none;

            .iconContainer
            {
                svg
                {
                    @include svgColor($black, true);
                }

                &::before
                {
                    border-color: $disabledColor !important;
                }
            }
        }

        .textRow,
        .subTextRow
        {
            display:         flex;
            flex-flow:       row nowrap;
            align-items:     center;
            justify-content: center;
            gap:             8px;
        }

        p
        {
            font-size:   20px;
            font-weight: $fontWeightBold;
        }

        &.black
        {
            color:            $white;
            border-color:     $black;
            background-color: $black;

            .iconContainer
            {
                svg
                {
                    @include svgColor($white);
                }
            }
        }

        &.gray
        {
            color:            $black;
            border-color:     $lightGray;
            background-color: $lightGray;

            .iconContainer
            {
                svg
                {
                    @include svgColor($black);
                }
            }
        }

        &.pill
        {
            border-radius: 200px;
            padding:       8px 22px;
            min-width:     auto;
            align-items:   flex-start;

            p
            {
                font-size: 14px;
            }
        }

        .iconContainer
        {
            display:     flex;
            align-items: center;

            &.iconContainerRight
            {
                padding-left: 12px;
            }

            svg
            {
                $svgSize: 24px;

                width:    $svgSize;
                height:   $svgSize;

                @include svgColor($white);

                > *
                {
                    transition: fill .2s linear,
                                stroke .2s linear;
                }
            }
        }

        &.checkout
        {
            border-radius: 15px;
            padding:       8px 12px;
            min-width:     auto;
            align-items:   flex-start;

            .textRow
            {
                p
                {
                    font-size: 16px;
                }
            }

            .subTextRow
            {
                p
                {
                    font-size: 10px;
                }

                .iconContainer
                {
                    svg
                    {
                        width:  16px;
                        height: 16px;
                    }
                }
            }

            &[disabled]
            {
                svg
                {
                    @include svgColor($white, true);
                }
            }
        }

        &.pressed
        {
            transform: scale(0.95);
        }

        &.text
        {
            border:           none;
            background-color: transparent;
            padding:          0;
            min-width:        auto;
            color:            $black;

            p
            {
                font-size:   16px;
                font-weight: $fontWeightBold;
            }

            &.textOrange
            {
                color: $orange;

                .iconContainer
                {
                    padding-left: 0;

                    svg
                    {
                        @include svgColor($orange);
                    }
                }
            }

            .iconContainer
            {
                svg
                {
                    @include svgColor($black);
                }
            }
        }
    }

    &:hover
    {
        .disabledHoverPopup
        {
            opacity: 1 !important;
        }
    }

    .disabledHoverPopup
    {
        transition:     opacity .2s linear;
        position:       absolute;
        opacity:        0;
        pointer-events: none;
        background:     $whiteGray;
        border:         1px solid $lightGray;
        padding:        20px;
        width:          300px;
        border-radius:  6px;
        left:           0;
        bottom:         calc(100% + 10px);
        text-align:     center;
    }
}

