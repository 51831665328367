//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.orderListItemWrapper
{
    overflow: hidden;

    .orderListItemHeader
    {
        display:                 grid;
        grid-template-columns:   repeat(5, 1fr);
        background:              $whiteGray;
        padding:                 20px;
        border-radius:           15px;

        > div
        {
            display:         flex;
            justify-content: center;
            align-items:     center;

            &.orderDetail
            {
                grid-column:     span 2;
                gap:             50px;
                justify-content: flex-start;

                div
                {
                    min-width: 180px;
                }

                .timeSlotString
                {
                    font-weight:   $fontWeightSemiBold;
                    margin-bottom: 10px;
                }

                .servingType
                {
                    align-items:   center;
                    display:       flex;
                    gap:           10px;
                    font-weight:   $fontWeightSemiBold;
                    margin-bottom: 10px;

                    > div
                    {
                        display:   flex;
                        flex-flow: column;
                    }
                }

                .customerArrivedButtonWrapper
                {
                    margin-bottom: 10px;

                    button
                    {
                        padding: 5px 10px;
                    }
                }

                .customerArrivedLabel
                {
                    margin-bottom: 10px;
                    color:         $brightRed;
                    font-weight:   $fontWeightSemiBold;
                }

                .orderLabel
                {
                    display:     flex;
                    gap:         20px;
                    align-items: center;
                    font-weight: $fontWeightSemiBold;

                    > a
                    {
                        > div
                        {
                            min-width: fit-content;
                        }
                    }
                }
            }

            &.orderPrice
            {
                display:   flex;
                flex-flow: column;

                :nth-child(2)
                {
                    font-weight: $fontWeightBold;
                }
            }

            &.orderStatusList
            {
                gap:             10px;
                justify-content: flex-end;
                grid-column:     span 2;
                align-items:     center;
                flex-flow:       row;
            }
        }

    }

    .orderListItemDetail
    {
        max-height: 0;
        display:    flex;
        flex-flow:  column;
        gap:        10px;
        opacity:    0;
        transition: opacity 0.2s linear,
                    max-height 0.2s linear,
                    padding 0.1s linear 0.2s;

        &.extended
        {
            opacity:    1;
            padding:    20px;
            max-height: 1500px;
            transition: opacity 0.2s linear 0.1s,
                        max-height 0.2s linear 0.1s,
                        padding 0.1s linear;
        }
    }
}
