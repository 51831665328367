//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

// because we have to override the react-date-picker styles
// stylelint-disable selector-class-pattern
.datePickerWrapper
{
    display: flex;
    gap:     10px;

    .datePicker
    {
        :global(.react-date-picker__wrapper)
        {
            border:    none;
            display:   flex;
            flex-flow: row-reverse;
            gap:       10px;

            :global(.react-date-picker__inputGroup)
            {
                pointer-events: none !important;
            }

            :global(.react-date-picker__calendar-button)
            {
                border-radius: 100%;
                background:    $lightGray;
                height:        40px;
                aspect-ratio:  1/1;
                transition:    scale 0.1s linear;

                &:hover
                {
                    scale: 1.1;
                }

                svg
                {
                    width: 18px;
                }
            }
        }

        :global(.react-date-picker__calendar--open)
        {
            position:       fixed !important;
            width:          100vw !important;
            height:         100vh !important;
            background:     $black50;
            top:            0 !important;
            left:           0 !important;
            pointer-events: none;
        }
    }

    .calendar
    {
        width:          500px !important;
        min-height:     300px;
        position:       fixed;
        top:            calc(25% - 150px);
        left:           calc(50% - 250px);
        border-radius:  15px;
        pointer-events: all;
        padding:        10px;

        button
        {
            border-radius: 12px;
        }

        *
        {
            font-family: $fontSourceGeneralBody;
        }

        :global(.react-calendar__navigation)
        {
            button:nth-child(3)
            {
                font-size: 18px;
            }
        }

        :global(.react-calendar__month-view__weekdays)
        {
            margin-bottom: 10px;

            :global(.react-calendar__month-view__weekdays__weekday)
            {
                abbr
                {
                    font-size:       14px;
                    text-decoration: none;
                }
            }
        }

        :global(.react-calendar__month-view__days),
        :global(.react-calendar__year-view__months),
        :global(.react-calendar__decade-view__years),
        :global(.react-calendar__century-view__decades)
        {
            margin: 0;
        }

        :global(.react-calendar__tile)
        {
            color: $black;

            &:global(.react-calendar__tile--now)
            {
                background:  $lightGray;
                font-weight: $fontWeightBold;
                color:       $black;

                &:hover
                {
                    background: $lightGray;
                }
            }

            &:global(.react-calendar__tile--active),
            &:global(.react-calendar__tile--hasActive)
            {
                font-weight: $fontWeightBold;
                background:  $orange;
                color:       $white;

                &:hover
                {
                    background: $orange;
                }
            }
        }
    }
}

// stylelint-enable selector-class-pattern