//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.loginScreen
{
    display:         flex;
    height:          100vh;
    align-items:     center;
    justify-content: center;

    .loginForm
    {
        width:          500px;
        display:        flex;
        flex-direction: column;
        gap:            20px;
        align-items:    center;

        div:not(:last-child)
        {
            width: 100%;
        }
    }
}
