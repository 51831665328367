//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.colorButton
{
    $height:         30px;

    min-height:      $height;
    font-size:       16px;
    min-width:       80px;
    padding:         0;
    font-weight:     $fontWeightBold;
    border-radius:   8px;
    display:         flex;
    justify-content: center;
    align-items:     center;
    border:          0;
    cursor:          pointer;
    transition:      background-color 0.1s linear;

    &.colorButtonColorThemeDefault
    {
        background: $gray;

        &:hover
        {
            background: $blue;
        }
    }

    &.colorButtonColorThemeOrange
    {
        background: $orange;
        color:      white;
        transition: scale 0.1s linear;

        &:hover
        {
            scale: 1.1;
        }
    }

    .iconWrapper
    {
        display: inline-block;
        margin:  0 0 -1px 0;

        &.iconWrapperLeft
        {
            margin-right: 10px;
            margin-left:  -10px;
        }

        &.iconWrapperRight
        {
            margin-right: -10px;
            margin-left:  10px;
        }
    }
}

