//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// stylelint-disable-next-line primer/no-unused-vars
$fontsPath:             '../assets/fonts/';
$fontSourceCourierNew:  source-code-pro,
                        menlo,
                        monaco,
                        consolas,
                        'Courier New',
                        monospace;
$fontSourceGeneralBody: 'Poppins',
                        'Roboto',
                        'Arial',
                        monospace,
                        -apple-system,
                        blinkmacsystemfont,
                        'Segoe UI',
                        'Oxygen',
                        'Ubuntu',
                        'Cantarell',
                        'Fira Sans',
                        'Droid Sans',
                        'Helvetica Neue',
                        sans-serif;

@font-face
{
    font-family: Roboto;
    font-style:  normal;
    font-weight: 400;
    src:         url('#{$fontsPath}Roboto/roboto-v30-latin-regular.eot');
    src:         local(''),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-regular.woff2') format('woff2'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-regular.woff') format('woff'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-regular.ttf') format('truetype'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-regular.svg#Roboto') format('svg');
}

@font-face
{
    font-family: Roboto;
    font-style:  normal;
    font-weight: 500;
    src:         url('#{$fontsPath}Roboto/roboto-v30-latin-500.eot');
    src:         local(''),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-500.woff2') format('woff2'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-500.woff') format('woff'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-500.ttf') format('truetype'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-500.svg#Roboto') format('svg');
}

@font-face
{
    font-family: Roboto;
    font-style:  normal;
    font-weight: 700;
    src:         url('#{$fontsPath}Roboto/roboto-v30-latin-700.eot');
    src:         local(''),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-700.woff2') format('woff2'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-700.woff') format('woff'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-700.ttf') format('truetype'),
                 url('#{$fontsPath}Roboto/roboto-v30-latin-700.svg#Roboto') format('svg');
}

@font-face
{
    font-display: swap;
    font-family:  Poppins;
    font-style:   normal;
    font-weight:  400;
    src:          url('#{$fontsPath}Poppins/poppins-v20-latin-regular.eot');
    src:          url('#{$fontsPath}Poppins/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-regular.woff2') format('woff2'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-regular.woff') format('woff'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-regular.ttf') format('truetype'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-regular.svg#Poppins') format('svg');
}

@font-face
{
    font-display: swap;
    font-family:  Poppins;
    font-style:   normal;
    font-weight:  500;
    src:          url('#{$fontsPath}Poppins/poppins-v20-latin-500.eot');
    src:          url('#{$fontsPath}Poppins/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-500.woff2') format('woff2'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-500.woff') format('woff'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-500.ttf') format('truetype'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-500.svg#Poppins') format('svg');
}

@font-face
{
    font-display: swap;
    font-family:  Poppins;
    font-style:   normal;
    font-weight:  700;
    src:          url('#{$fontsPath}Poppins/poppins-v20-latin-700.eot');
    src:          url('#{$fontsPath}Poppins/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-700.woff2') format('woff2'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-700.woff') format('woff'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-700.ttf') format('truetype'),
                  url('#{$fontsPath}Poppins/poppins-v20-latin-700.svg#Poppins') format('svg');
}

:export
{
    fontsourcecouriernew:  $fontSourceCourierNew;
    fontsourcegeneralbody: $fontSourceGeneralBody;
}
