//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.menuItem
{
    display:       flex;
    flex-flow:     column;
    align-items:   center;
    margin-bottom: 40px;
    gap:           20px;

    &:last-child
    {
        margin-bottom: 0;
    }

    .menuRow
    {
        display:         flex;
        justify-content: center;
        align-items:     center;
        gap:             20px;
    }
}
