//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.loadingOverlay
{
    display:    none;
    top:        0;
    left:       0;
    background: $white80;
    z-index:    $zIndexLoadingOverlay;
    position:   fixed;
    width:      100%;
    height:     100%;
    overflow:   hidden;

    .loadingOverlaySpin
    {
        margin: auto;

        .loadingOverlayText
        {
            position:     absolute;
            margin-top:   120px;
            left:         0;
            right:        0;
            margin-left:  auto;
            margin-right: auto;
            max-width:    90%;
            height:       200px;
            text-align:   center;
            font-size:    30px;

            @media (min-width: $screen-md)
            {
                max-width: 800px;
                font-size: 40px;
            }
        }
    }
}

@keyframes spinAnimation
{
    0%
    {
        transform:  rotate(0deg);
        box-shadow: 0 0 0 0 $lightGray;
    }

    70%
    {
        box-shadow: 0 0 5px 5px $lightGray;
        scale:      1.2;
    }

    90%
    {
        transform:  rotate(1440deg);
        box-shadow: 0 0 0 0 $lightGray;
        scale:      1;
    }

    100%
    {
        transform:  rotate(1440deg);
        box-shadow: 0 0 0 0 $lightGray;
    }
}

.loadingOverlayVisible
{
    display: flex;

    .loadingOverlaySpin
    {
        div:first-child
        {
            position:      absolute;
            margin-top:    -100px;
            margin-left:   -100px;
            width:         200px;
            height:        200px;
            border-radius: 50%;
            background:    radial-gradient($white, $whiteGray);
            border:        1px solid $lightGray;
            animation:     spinAnimation 1.5s infinite;

            &:nth-child(2)
            {
                animation-delay: 0.3s;
            }
        }
    }
}
