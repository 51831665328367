//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$size:   30px;
$offset: calc($size / 2);

.circleIcon
{
    width:         $size;
    height:        $size;
    display:       flex;
    align-items:   center;
    text-align:    center;
    border-radius: 100%;
    cursor:        pointer;

    @include scaleOnHover(1.2);

    span
    {
        margin: 0 auto;
    }

    svg
    {
        width:  $size - $offset;
        height: $size - $offset;
    }

    &.pressed
    {
        scale: 0.95;
    }

    &.big
    {
        $size:   50px;
        $offset: calc($size / 2);

        width:   $size;
        height:  $size;

        svg
        {
            width:  $size - $offset;
            height: $size - $offset;
        }
    }

    &.circleOrange
    {
        background: $orange;

        svg
        {
            @include svgColor($white);
        }
    }

    &.circleGray
    {
        background: $lightGray;

        svg
        {
            @include svgColor($black);
        }
    }

    &.circleBlack
    {
        background: $black;

        svg
        {
            @include svgColor($white);
        }
    }

    &.circleTransparent
    {
        background: $transparent;
    }
}
