//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$blue:         #3b55e6;
$red:          #ff0000;
$red80:        rgba(255 0 0 / 80%);
$white80:      rgba(255 255 255 / 80%);
$white:        #ffffff;
$black:        #1c1c1b;
$black20:      #00000033;
$black50:      #00000080;
$doveGray:     #646363;
$lightGray:    #eaeaea;
$whiteGray:    #f8f7f7;
$gray:         #d8d8d8;
$gold:         #cc9015;
$darkGold:     #cb9214;
$lightGold:    #ffe991;
$gravel:       #f6f5f4;
$orange:       #ff8a00;
$orange80:     rgba(255 138 0 / 80%);
$darkOrange:   #311f09;
$primaryColor: $black;
$transparent:  transparent;
$lightSky:     #ebf6fa;
$mintGreen:    #3fc66e;
$lightBlue:    #8aeaff;
$brightRed:    #ff3838;
/* stylelint-disable property-case, property-no-unknown */
:export
{
    blue:    $blue;
    gray:    $gray;
    red:     $red;
    red80:   $red80;
    white:   $white;
    white80: $white80;
}

/* stylelint-enable property-case, property-no-unknown */
